
.invite-btn {
  &:hover {
    background-color: $Light-Primary-Color;
  }
}

.upload-example-btn {
  &:hover {
    background-color: $Blue-Color;
  }
}


/*List ExampleSummary Start*/
.example-summary-main {
  &-white {
    background-color: white;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
  }
  .example-edit-btn {
    outline: none;
    border: none;
    border-radius: 100px;
    background-color: $Primary-Color;
    padding: 5px;
    width: 25px;
    height: 25px;

  }
.comment-numbers {
  border-bottom: 1px solid #ececec;
  padding-bottom: 15px;
  padding-right: 25px;
  margin-bottom: 10px;

  .like-wrapper {
    border-radius: 50%;
    background-color: #67BEC9;
    padding: 4px;
    border: 1px solid black;
    width: 25px;
    height: 25px;
    .like-overview {
      stroke: black;
      fill: white;
      width: 16px;
      height: 16px;
    }
  }
}

  .example-buttons {
    padding: 15px 10px;

    @media all and (min-width: 768px) {
      padding: 15px 20px;
    }

    &:hover {
      background-color: $blue-green-color;
      border-radius: 10px;
    }
  }
}
.result-card-block {
  background-color: $White-Color;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  //padding: 80px 7px 80px 7px;
  position: relative;
  z-index: 0;
  height: 400px;
  width: 100%;

  &.cp {
    height: 310px;
  }

  .result-card {
    height: 100%;
    display: flex;
    align-content: center;
    background-color: #fafafa;
    padding: 10px;
    border-radius: 10px;
  }

  .result-card-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    align-self: center;
  }

  .result-card-b {
    position: relative;
    z-index: 0;

    .conversions {
      position: absolute;
      top: 80px;
      right: -5px;
      transform: translate(0, -30%);
      z-index: 1;
      border-radius: 100%;
      width: 120px;
      height: 120px;
      font-family: Topol;
      font-weight: bold;

      &.conversions-1 {
        right: 125px;
      }
    }
  }

  .result-card-option {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


/*List ExampleSummary End*/

/*SearchBar Start*/
.search-bar-box {
  position: relative;

  .search-bar-img {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translate(0, -50%);
  }
}

/*SearchBar End*/
