/*Invite User Success Modal Start*/
.invite-user-success-modal{
  margin-top: 15vh;
  max-width: 410px;
  padding-left: 20px;
  position: relative;
  &::after{
    position: absolute;
    content: "";
    width: 20px;
    border-radius: 15px 0 0 15px;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: $Green-Color;
  }
}
/*Invite User Success Modal End*/

/*Invite User Start*/
.csv-upload-error {
  background-color: #FEE1E5;
  padding: 20px;
  border-radius: 5px;
  min-height: 60px;
  border: 1px solid $Red-Color;
}
/*Invite User End*/

/*Manage Users Start*/
.manage-user-block{
  width: 300px;
  @media screen and (max-width: 767px){
    width: 250px;
  }
}
/*Manage Users End*/

/*Manage User Modal Start*/
.manage-user-modal-img{
  width: 95px;
  height: 95px;
  border-radius: 50%;
  @media screen and (max-width: 767px){
    width: 65px;
    height: 65px;
  }
}
.manage-user-modal-img-block{
  position: relative;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  z-index: 0;
  .manage-user-modal-img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 95px;
    min-width: 95px;
    height: 95px;
    border-radius: 50%;
    cursor: pointer;
    pointer-events: none;
  }
}
.manage-user-modal-img-input{
  width: 95px;
  height: 95px;
  min-width: 95px;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
}
/*Manage User Modal End*/