.checkexamples {
  padding-top: 80px;
  .statuslist {
    button {
      border-bottom: 2px solid $Secondary-Color;
      color: $Light-Primary-Color;
      padding: 4px 0;
      margin: 0 10px;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 14px;
      &.active-status, &:hover {
        text-decoration: none;
        color: $Primary-Color;
        border-color: $Primary-Color;
      }
    }
  }
}

.lh1 {
  line-height: 1;
}

.statusindicator {
  border: 1px solid $Light-Primary-Color;
  border-radius: 12px;
  text-align: center;
  display: inline-block;
  padding: 5px 8px;
  line-height: 1;
  text-transform: uppercase;
}
