.login-main {
  min-height: 100vh;
  width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 0;

  .clip {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    clip-path: polygon(54% 0, 100% 0%, 100% 100%, 54% 100%, 48% 60%);
    z-index: -1;
    @media screen and (max-width: 767px) {
      clip-path: none;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $Primary-Color;
      z-index: -3;
      @media screen and (max-width: 767px) {
        background-color: transparent;
      }
    }
  }

  .login-left {
    min-height: 100vh;

    @media screen and (max-width: 767px) {
      min-height: auto;
    }
  }

  .login-right-img {
    position: relative;
    z-index: 0;
    .hex-vector{
      position: absolute;
      top: -10%;
      left: 33%;
      z-index: -1;
      width: 400px;
      height: 500px;
      @media screen and (min-width: 992px) and (max-width: 1199px){
        width: 300px;
        height: 400px;
      }
      @media screen and (max-width: 991px){
        width: 250px;
        height: 300px;
      }
      @media screen and (max-width: 576px){
       display: none;
      }
    }
  }
}

/* Update Profile Details Start */
.modal-content {
  border-radius: 20px;
}

.update-profile-main {
  border-radius: 20px;

  .upload-profile-wrapper {
    background: $Grey-Color 0% 0% no-repeat padding-box;
    border: 3px solid $White-Color;
    color: $Light-Black-Color;
    border-radius: 100%;
    height: 171px;
    width: 171px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;

    .preview-image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      height: 171px;
      width: 171px;
      border-radius: 50%;
    }

    .upload-profile-picture {
      cursor: pointer;
      border-radius: 100%;
      height: 171px;
      width: 171px;
      opacity: 0;
    }

    .upload-profile-text {
      position: absolute;
      top: 45%;
      margin: 0 auto;
      pointer-events: none;
    }
    .upload-profile-close-btn{
      position: absolute;
      top: 0;
      right: 0;
      z-index: 5;
    }
  }


  .first-login-yt-frame {
    min-width: 200px;
    min-height: 130px;
    background: $Grey-Color;
    border-radius: 20px;
  }
}

/* Update Profile Details End */

/* profile section */


.profile-wrapper {
  background: $White-Color 0% 0% no-repeat padding-box;
  border: 1px dashed $Light-Primary-Color;
  color: #514D59;
  height: 300px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
  border-radius: 8px;
  @media screen and (max-width: 768px){
    height: 200px;
  }

  .profile-picture {
    cursor: pointer;
    height: 300px;
    width: 100%;
    opacity: 0;
    @media screen and (max-width: 768px){
      height: 200px;
    }
  }

  .profile-button {
    position: absolute;
    top: 50%;
    margin: 0 auto;
    pointer-events:none
  }


}

.file-upload-error {
  background-color: #FEE1E5;
  padding: 20px;
  border-radius: 5px;
  height: 60px;
  border: 1px solid $Red-Color;
}

/* The check-main-radio */
.check-main-radio {
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  color: $Primary-Color;
}


.profile-right {
  border: 1px solid $white-light;
  opacity: 1;
  width: 100%;
  border-radius: 10px;
  padding: 10px 20px;
}

