.account-user-img-block {
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  z-index: 0;

  .account-profile-picture {
    //position: absolute;
    //top: 0;
    //left: 0;
    //right: 0;
    //bottom: 0;
    width: 160px;
    min-width: 160px;
    height: 160px;
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    pointer-events: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .account-badge-block {
    margin-top: 10px;

    span {
      white-space: nowrap;
    }
  }
}

.account-img-main-block {
  position: relative;

  .account-image-error {
    @media screen and (min-width: 991px) {
      position: absolute;
      top: 165px;
      left: 0;
    }
  }
}

.account-prefilled-info {
  min-height: 72px;
  border-radius: 8px;
  border: 1px solid $Light-Primary-Color;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 576px) {
    min-height: 52px;
    padding: 10px 10px;
  }

  .account-prefilled-info-title {
    font-size: 14px;
    color: $Light-Primary-Color;
    @media screen and (max-width: 576px) {
      font-size: 11px;
    }
  }

  .account-prefilled-info-data {
    font-size: 14px;
    color: $Primary-Color;
    @media screen and (max-width: 576px) {
      font-size: 11px;
    }
  }
}

.account-user-img {
  width: 160px;
  height: 160px;
  min-width: 160px;
  border: 3px solid #FFFFFF;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
}

.pictureEditButton {
  position: absolute;
  top: 115px;
  left: 75%;
}

.account-invite-user-img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  object-fit: cover;
}
