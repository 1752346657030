body {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header-logo {
  max-width: 90px;
  @media screen and (max-width: 767px) {
    max-width: 75px;
  }
}

.header-main {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  background-color: $Primary-Color;
  color: $White-Color;

  .header-inbox-btn{
    border-right: 1px solid #8D80A3;

  }
  .header-inbox {
    position: relative;
    border: none;
    outline: none;

    .notification {
      position: absolute;
      z-index: 1;
      right: -5px;
      top: -5px;
      border-radius: 50px;
      background-color: $Red-Color;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      font-size: 10px;
    }
  }
  .header-user-img {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: 2px solid $White-Color;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    object-fit: cover;

  }
  .header-user-info {
    position: relative;
    cursor: pointer;



    .menu {
      position: absolute;
      //bottom: -40px;
      z-index: 1;
      top: 0;
      right: 40px;
      width: 200px;
      min-height: auto;
      background-color: $White-Color;
      padding: 20px;
      box-shadow: 0px 4px 5px #00000024;
      border: 1px solid #DDDDDD;
      border-radius: 10px;

    ul {
      min-height: auto;

      li {
        display: block;

        a {
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
        }

        .menu-item {
          padding: 3px 10px;
          display: block;
          width: 100%;
          margin-bottom: 0;
          color: $Black-Color;
          border-radius: 4px;
          white-space: nowrap;


          &:hover {
            background-color: #EAE8ED;
          }
        }

        .sign-out {
          color: $Red-Color;

          &:hover {
            background-color: #FF9EAB33;
          }
        }
      }
    }
    }
  }

}

