$White-Color: #ffffff;
$Black-Color: #1D1D1D;
$Primary-Color: #615874;
$Light-Primary-Color: #8D80A3;
$Extra-Light-Primary-Color: #EAE7EE;
$Secondary-Color: #F4F3F6;
$Dark-Secondary-Color: #EAE8ED;
$Red-Color: #DB4C5F;
$Blue-Color: #63BECA;
$Dark-Blue-Color: #4EB5C2;
$Light-Blue-Color: #7ACDD6;
$Light-Black-Color: #514D59;
$Grey-Color: #EEEEEE;
$Light-Grey-Color: #6F6F6F;
$Dark-Red-Color: #C92D41;
$white-light:#D0CDE1;
$Orange-Color:#EC8421;
$Dark-Orange-Color:#FF9100;
$Yellow-Color: #FFB001;
$Dark-grey:#B3B3B3;
$Light-Red-Color: #F3364F;
$Green-Color: #6AA84F;
$Dark-Green-Color: #5e8b44;
$Default-Svg-Color: #a9a0ba;
$blue-green-color: #E1F2F5;
