.conversion-main {
  border-radius: 100%;
  width: 250px;
  height: 250px;
  font-family: Topol;
  @media screen and (max-width: 991px) {
    width: 200px;
    height: 200px;
  }
}

.zoom-icon {
  fill: $Primary-Color;
}

.zoom-btn {
  color: $Primary-Color;
  background-color: white;
  border: 1px solid $Primary-Color;

  &:hover {
    color: $White-Color;
    background-color: $Blue-Color;
    border: 1px solid $Blue-Color;

    .zoom-icon {
      fill: white;
    }
  }
}

.zoominwrapper {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.view-example-body {
  &.check-view-example-body {
    padding-top: 140px;
  }

  .result-card-block {
    background-color: $White-Color;
    border-radius: 10px;
    border: 1px solid #d7d7d7;
    //padding: 80px 7px 20px 7px;
    height: 310px;
    width: 100%;
    min-height: 560px;
    position: relative;
    z-index: 0;
    @media screen and (max-width: 991px) {
      min-height: 400px;
    }


    .result-card {
      height: 100%;
      display: flex;
      align-content: center;
      background-color: #fafafa;
      padding: 10px;
      border-radius: 10px;
    }

    .result-card-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      align-self: center;
    }

    .result-card-b {
      position: relative;
      z-index: 0;

      .conversions {
        position: absolute;
        font-family: Topol;
        top: 80px;
        right: -5px;
        transform: translate(0, -30%);
        z-index: 1;
        border-radius: 100%;
        width: 120px;
        height: 120px;
        font-weight: bold;

        &.conversions-1 {
          right: 125px;
        }
      }
    }

    .result-card-option {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .example-yt-video {
    text-align: left !important;
    width: 100%;
    height: 350px;
    border-radius: 10px;
    @media screen and (max-width: 1024px) {
      height: 250px;
    }
  }
}

.modal-open .modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

/*Image Zoom Modal*/
.image-zoom-modal-wrapper {
  width: 97vw;
  height: 97vh;
  max-width: 97vw;
  max-height: 97vh;
  margin: 0 !important;
}

.image-zoom-modal {
  height: 100%;
  width: 100%;
  padding: 0 5px;
  @media screen and (max-width: 576px) {
    height: 95vh;
  }
}


.image-zoom-modal-body {
  padding: 0 50px;
  height: calc(97vh - 96px);

  //@media screen and (max-width: 576px) {
  //  padding-left: 10%;
  //  padding-right: 10%;
  //}
}

.carousel {
  display: flex;
  align-items: center;
}

.zoomed-image {
  //max-width: 100%;
  //max-height: calc(97vh - 96px);
  height: auto;
  width: auto;
}


.img-zoom-modal-img {
  position: relative;
  //max-width: 100%;
  //max-height: calc(97vh - 96px);
  width: auto;
  height: auto;

  .img-zoom-modal-img-conversion {
    position: absolute;
    top: -45px;
    right: -20px;
    border-radius: 100%;
    width: 180px;
    height: 180px;
    font-family: Topol;
    @media screen and (max-width: 991px) {
      width: 100px;
      height: 100px;
    }
  }
}

.carousel-control-next, .carousel-control-prev {
  width: 30px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  background-size: 100%, 100%;
  border-radius: 50%;
  background-image: none;
}

.carousel-control-prev {
  margin-left: 7px;
}

.carousel-control-next {
  margin-right: 7px;
}

.carousel-control-next-icon:after {
  content: '>';
  font-size: 45px;
  color: black;
  @media screen and (max-width: 576px) {
    font-size: 35px;
  }
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 45px;
  color: black;
  @media screen and (max-width: 576px) {
    font-size: 35px;
  }
}

/*Comments Section*/
.comment-user-img-block {
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.comment-user-img {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  object-fit: cover;
}

.comment-star-img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.comment-main {
  padding: 30px;
  background-color: $White-Color;
  border: 1px solid $white-light;
  border-radius: 10px;
  @media screen and (max-width: 767px) {
    padding: 15px;
  }
}

/*Share With User Modal Start*/
.share-with-user-modal {
  max-width: 410px;
}

.share-with-user-btn {
  display: inline-block;
  border: none;
  border-radius: 8px;
  height: 50px;
  width: 100%;
  padding: 10px 20px;
  color: $White-Color;
  background-color: $Red-Color;

  &:focus:not([disabled]), &:hover:not([disabled]) {
    outline: none;
    background-color: $Dark-Red-Color;
  }

  @media screen and (max-width: 767px) {
    height: 45px;
  }
}

/*Share With User Modal End*/

//carousel
.carousel-item {
  overflow-y: auto;
  overflow-x: auto;
  max-height: calc(97vh - 96px);
  max-width: 100%;
}
