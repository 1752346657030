.footer-main{
  background-color: $Primary-Color;
  color: $White-Color;
}

.footer-logo {
  max-width: 190px;
  @media screen and (max-width: 767px) {
    max-width: 90px;
  }
}