.inbox-content{
  padding: 30px;
  border-radius: 15px;
  @media screen and (max-width: 768px){
    padding: 15px;
  }
}
.unread-notification{
  background-color: $Extra-Light-Primary-Color;
  border: 1px solid $white-light;
  position: relative;
  .unread-icon{
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50px;
    background: $Light-Primary-Color;
    left: -30px;
    top: 0;
    @media screen and (max-width: 767px) {
      left: -15px;
    }
  }
}

.read-notification{
  background-color: $White-Color;
  border: 1px solid $white-light;
  position: relative;
  .unread-icon{
    position: absolute;
   display: none;
  }
}

.read-notification{
  background-color: $White-Color;
  border: 1px solid $white-light;
}

.inbox-user-img-block{
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.inbox-user-img{
  width: 56px;
  height: 56px;
  border-radius: 100%;
  object-fit: cover;
}
.inbox-right-side{
  .inbox-comment{
    background-color: $White-Color;
    border-radius: 8px;
    border: 1px solid $white-light;
    padding: 30px;
    @media screen and (max-width: 768px){
      padding: 15px;
    }
  }
}
.notification-block{
  border: 1px solid #D0CDE1;
  border-radius: 10px;
  background-color: $White-Color;
  padding: 30px;
  @media screen and (max-width: 768px){
    padding: 15px;
  }
}

.inbox-menu-main{
  position: relative;
  align-self: flex-start;

  .inbox-menu {
    position: absolute;
    bottom: -40px;
    z-index: 1;
    top: 0;
    right: 13px;
    width: 200px;
    min-height: 60px;
    background-color: $White-Color;
    padding: 8px 15px;
    box-shadow: 0px 4px 5px #00000024;
    border: 1px solid #DDDDDD;
    border-radius: 10px;

    li {
      display: block;
      a{
        text-decoration: none;
        &:hover{
          text-decoration: none;
        }
      }
      .inbox-menu-item {
        padding: 3px 10px;
        margin: 5px 0;
        display: block;
        width: 100%;
        margin-bottom: 0;
        color: $Black-Color;
        border-radius: 4px;
        &:hover {
          background-color: #EAE8ED;
        }
      }
    }
  }

}
