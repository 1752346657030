
.loader-main {
  min-height: 100vh;
  min-width: 100vw;
  opacity: 0.5;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: $Secondary-Color;
  .loader {
    width: 10em;
    height: 10em;
    border-top: 1em solid $Primary-Color;
    border-right: 1em solid transparent;
    border-radius: 50%;
    margin: auto;
    animation: loader 0.7s linear infinite;

  }

  .head {
    width: 1em;
    height: 1em;
    background-color: $Primary-Color;
    border-radius: 50%;
    margin-left: 8.5em;
    margin-top: 0.5em;
  }

  @keyframes loader {
    100% {
      transform: rotate(360deg)
    }
  }
}